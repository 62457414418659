import Vue from 'vue';
/* 导入路由 */
import VueRouter from 'vue-router';
/* 导入注册我们自己的组件 */
// import home from '../components/Home.vue'
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'map',
		component: () => import('../components/map.vue')
	},
	
	{
		path: '/time',
		name: 'time',
		component: () => import('../components/time.vue')
	},
	{
		path: '/ster',
		name: 'ster',
		component: () => import('../components/ster.vue')
	},
	{
		path: '/shop',
		name: 'shop',
		component: () => import('../components/shop.vue')
	},
	{
		path: '/serve',
		name: 'serve',
		component: () => import('../components/serve.vue')
	},
	{
		path: '/main',
		name: 'main',
		component: () => import('../components/main.vue')
	},
	{
		path: '/lacoth',
		name: 'lacoth',
		component: () => import('../components/lacoth.vue')
	},
	{
		path: '/amount',
		name: 'amount',
		component: () => import('../components/amount.vue')
	},
]


/* 定义组件路由 */
const router = new VueRouter({
	routes
})

//导出路由对象
export default router;