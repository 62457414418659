<template>
	<div>
		<VScaleScreen class="secj" width="1920" height="1080">
			<!-- <div>
			<router-link to="/home"> 首页</router-link>
			<router-link to="/about">关于</router-link>
		</div> -->
			<router-view></router-view>
		</VScaleScreen>
	</div>


</template>

<script>
	import VScaleScreen from 'v-scale-screen'
	export default {
		name: 'App',
		components: {
			VScaleScreen
		}
	}
</script>

<style>
	.secj {
		background: #120924 !important;
	}
</style>